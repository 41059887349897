const NL = {
    login: "inloggen",
    register: "registreren",
    email: "emailadres",
    password: "wachtwoord",
    enterEmail: "vul je email adres in",
    enterPassword: "vul je wachtwoord in",
    fieldRequired: "veld is verplicht",
    newHere: "nieuw hier?",
    findPhotoProjectsBack: "sla je fotoprojecten op en vind ze terug",
    savedCredentialsFasterPayment: "met je bewaarde gegevens bestel je sneller",
    findBackOrders: "vind je bestellingen gemakkelijk terug",
    forgotPassword: "wachtwoord vergeten?",
    forgotPasswordText1: "Vul hieronder je e-mailadres in. Dan sturen wij je een e-mail met een link zodat je een nieuw wachtwoord kunt instellen.",
    forgotPasswordText2: "LET OP: dit kan even duren, check ook je SPAM folder.",
    requestPassword: "wachtwoord aanvragen",
    mailSent1: "we hebben je een e-mail gestuurd",
    mailSent2: "Als het emailadres bekend is, is er een email verstuurd naar",
    close: "sluiten",
    back: "terug",
    howToAddress: "hoe wil je aangesproken worden?",
    mr: "de heer",
    mrs: "mevrouw",
    neutral: "neutraal",
    firstName: "voornaam",
    middleName: "tussenvoegsel",
    lastName: "achternaam",
    companyName: "bedrijfsnaam",
    country: "land",
    postalCode: "postcode",
    houseNumber: "huisnummer",
    addition: "toevoeging",
    address: "adres",
    city: "plaatsnaam",
    phoneNumber: "telefoonnummer",
    invalidEmail: "Dit is geen geldig e-mailadres",
    invalidPostalCode: "Dit is geen geldige postcode",
    invalidPhone: "Dit is geen geldig telefoonnummer",
    passwordMinLength: "Het wachtwoord moet minimaal 8 tekens lang zijn.",
    passwordMaxLength: "Het wachtwoord moet maximaal 20 tekens lang zijn.",
    passwordsDoNotMatch: "wachtwoorden komen niet overeen",
    confirmPassword: "wachtwoord bevestigen",
    confirmNewPassword: "bevestig nieuw wachtwoord",
    newPassword: "nieuw wachtwoord",
    registerTitle: "registreren",
    enterDetails: "Voer hieronder je gegevens in.",
    passwordHint: "Vul een sterk wachtwoord in met cijfers, hoofd- en kleine letters en leestekens. Gebruik minstens 8 en maximaal 20 tekens.",
    netherlands: "Nederland",
    belgium: "België",
    changeDetails: "wijzig gegevens",
    saveChanges: "wijzigingen opslaan",
    cancel: "annuleren",
    changeShippingAddress: "wijzig bezorgadres",
    myDataTitle: "mijn gegevens",
    removeAccount: "verwijder je account",
    changePassword: "wachtwoord wijzigen",
};

const FR: typeof NL = {
    login: "se connecter",
    register: "s'inscrire",
    email: "adresse e-mail",
    password: "mot de passe",
    enterEmail: "entrez votre adresse e-mail",
    enterPassword: "entrez votre mot de passe",
    fieldRequired: "champ requis",
    newHere: "nouveau ici?",
    findPhotoProjectsBack: "enregistrez vos projets photo et retrouvez-les",
    savedCredentialsFasterPayment: "avec vos données sauvegardées, commandez plus rapidement",
    findBackOrders: "retrouvez facilement vos commandes",
    forgotPassword: "mot de passe oublié?",
    forgotPasswordText1: "Entrez votre adresse e-mail ci-dessous. Nous vous enverrons un e-mail avec un lien pour définir un nouveau mot de passe.",
    forgotPasswordText2: "REMARQUE: cela peut prendre un moment, vérifiez également votre dossier SPAM.",
    requestPassword: "demander un mot de passe",
    mailSent1: "nous vous avons envoyé un e-mail",
    mailSent2: "Si l'adresse e-mail est connue, un e-mail a été envoyé à",
    close: "fermer",
    back: "retour",
    howToAddress: "comment souhaitez-vous être adressé(e) ?",
    mr: "monsieur",
    mrs: "madame",
    neutral: "neutre",
    firstName: "prénom",
    middleName: "particule",
    lastName: "nom",
    companyName: "nom de l'entreprise",
    country: "pays",
    postalCode: "code postal",
    houseNumber: "numéro",
    addition: "complément",
    address: "adresse",
    city: "ville",
    phoneNumber: "numéro de téléphone",
    invalidEmail: "Ce n'est pas une adresse e-mail valide",
    invalidPostalCode: "Ce n'est pas un code postal valide",
    invalidPhone: "Ce n'est pas un numéro de téléphone valide",
    passwordMinLength: "Le mot de passe doit contenir au moins 8 caractères.",
    passwordMaxLength: "Le mot de passe ne doit pas dépasser 20 caractères.",
    passwordsDoNotMatch: "les mots de passe ne correspondent pas",
    confirmPassword: "confirmer le mot de passe",
    confirmNewPassword: "confirmer le nouveau mot de passe",
    newPassword: "nouveau mot de passe",
    registerTitle: "s'inscrire",
    enterDetails: "Veuillez saisir vos coordonnées ci-dessous.",
    passwordHint: "Entrez un mot de passe fort avec des chiffres, des majuscules, des minuscules et des signes de ponctuation. Utilisez entre 8 et 20 caractères.",
    netherlands: "Pays-Bas",
    belgium: "Belgique",
    changeDetails: "modifier les coordonnées",
    saveChanges: "enregistrer les modifications",
    cancel: "annuler",
    changeShippingAddress: "modifier l'adresse de livraison",
    myDataTitle: "mes données",
    removeAccount: "supprimer ton compte",
    changePassword: "modifier le mot de passe",
};

const translations: Record<Window['culture'], typeof NL> = {
    NL,
    FR
};

export const getTranslation = <T extends LanguageKey>(key: T): typeof NL[T] => {
    if (window.culture === "fr-BE")
        return translations["FR"][key]

    return translations["NL"][key]
}

export type LanguageKey = keyof typeof NL