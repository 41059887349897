import m from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { hideScrollbars, mounter } from "../../utils"

import { Flyout } from "oj-mithriljs-packages/dist/Flyout/Flyout"
import { Svg } from "../../svg"
import { LoginForm } from "./loginForm"
import { LoginRegisterForm } from "./loginRegisterForm"
import { cleanString } from "oj-utils"

export interface ILoginFlyout {
    btnText: string;
    token: string;
    loginUrl: string;
    registerUrl: string;
    className?: string;
    skipRedirect?: boolean;
    registerRedirectUrl?: string;
}


export class LoginFlyout extends MithrilTsxComponent<ILoginFlyout> {
    open: boolean = false;
    dom: Element

    view(v: m.Vnode<ILoginFlyout>) {
        return [
            <button class={cleanString("sub-item", v.attrs.className)}
                aria-label={v.attrs.btnText}
                onclick={() => {
                    this.open = true
                    hideScrollbars(true)
                }}>
                <i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/profile.svg" /></i><span>{v.attrs.btnText}</span>
            </button>,
            <Flyout
                className="LoginFlyout"
                open={this.open}
                ondismiss={() => hideScrollbars(false)}
                onopen={open => this.open = open}
                dismiss
                animate
            >
                <header className="flyout-nav-header">
                    <button className="close" onclick={e => {
                        hideScrollbars(false)
                        this.open = false
                    }}>
                        <Svg src="/FrontendWebpack/dist/assets/newIcons/close.svg" />
                    </button>
                </header>
                <div className="flyout-nav-body">
                    <LoginForm token={v.attrs.token} isFlyout={true} loginUrl={v.attrs.loginUrl} skipRedirect={v.attrs.skipRedirect} />
                    <LoginRegisterForm registerUrl={v.attrs.registerUrl} redirectUrl={v.attrs.registerRedirectUrl} />
                </div>
            </Flyout>]
    }

    oncreate(v: m.VnodeDOM<{}>) {
        this.dom = v.dom
    }
}

mounter("[data-m-login-flyout]", (r, d: ILoginFlyout) => {
    m.mount(r, {
        oncreate: dom => r.replaceWith(dom.dom),
        view: v => <LoginFlyout {...d} />
    })
})