import m, { Vnode } from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { getTranslation } from "./translations"
import { formatPrice, formatHtmlPrice } from "../../utils";
import { cleanString } from "oj-utils";

export interface IPriceOverview {
    subPrice: number,
    processingPrice: number,
    shippingPrice: number,
    discountPrice: number,
    totalPrice: number,
    loyaltyHemaPoints?: number
    hideTitle?: boolean
}

export class PriceOverview extends MithrilTsxComponent<IPriceOverview> {

    view(v: Vnode<IPriceOverview>) {
        return <div className="price-overview">
            {!v.attrs.hideTitle && <header>
                <span className="h3">{getTranslation("receipt")}</span>
            </header>}
            <ul className="overview">
                <li><span className="label">{getTranslation("totalPriceItems")}</span><span className="price">{formatPrice(v.attrs.subPrice, false)}</span></li>
                {v.attrs.processingPrice > 0 && <li><span className="label">{getTranslation("processingFees")}</span><span className="price">{formatPrice(v.attrs.processingPrice, false)}</span></li>}
                <li><span className="label">{getTranslation("deliveryFees")}</span><span className={cleanString("price", v.attrs.shippingPrice === 0 && "free")}>{v.attrs.shippingPrice > 0 ? formatPrice(v.attrs.shippingPrice, false) : getTranslation("free")}</span></li>
                {v.attrs.discountPrice > 0 && <li className="discount"><span className="label">{getTranslation("discount")}</span><span className="price">- {formatPrice(v.attrs.discountPrice, false)}</span></li>}
                {/* <li className="discount"><span className="label">{getTranslation("discountCode")}</span><span className="price">- 5.00</span></li> */}
                <li className="total pricing align-right"><span className="label">{getTranslation("totalAmount")}</span><span className="price">{m.trust(formatHtmlPrice(v.attrs.totalPrice))}</span></li>
                {v.attrs?.loyaltyHemaPoints > 0 && <li><span className="label">{getTranslation("savedPoints")}</span><span className="price">{v.attrs.loyaltyHemaPoints} {getTranslation("points")}</span></li>}
            </ul>
        </div>
    }
}
