import m, { Vnode } from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { cleanString } from "oj-utils";
import { getTranslation } from "../orderCheck/translations";
import { type IShoppingCart } from "../shoppingCart";
import { convertCartLines, type ICartGroupItem, type ILocalCartItem } from "../shoppingCart/logic";
import { PriceOverview, type IPriceOverview } from "../shoppingCart/priceOverview";
import { formatHtmlPrice } from "../../utils";
import { ICheckoutSteps } from "./checkoutSteps";


interface ICartSummary extends Omit<IShoppingCart, 'orderDeliveryOptions' | 'discountCode' | 'infoBlocks' | 'productsFailedMessage' | 'loginUrl' | 'registerUrl' | 'loginToken'>, ICheckoutSteps { }

export class CartSummary extends MithrilTsxComponent<ICartSummary> {
    cartUrl: string
    cartLines: (ICartGroupItem | ILocalCartItem)[] = []
    priceOverview: IPriceOverview = null

    async oninit(v: Vnode<ICartSummary>) {
        this.cartUrl = v.attrs.shoppingCartUrl
        this.cartLines = convertCartLines(v.attrs.shoppingCartLines)
        this.priceOverview = {
            subPrice: v.attrs.subPrice,
            processingPrice: v.attrs.processingPrice,
            shippingPrice: v.attrs.shippingPrice,
            discountPrice: v.attrs.discountPrice,
            totalPrice: v.attrs.totalPrice,
            loyaltyHemaPoints: v.attrs.loyaltyHemaPoints
        }
    }

    view(v: Vnode<ICartSummary>) {
        return <div className="cart-summary">
            <header>
                <span className="title h3">{getTranslation("yourOrder")}</span>
                <a className="link" href={this.cartUrl}><span>{getTranslation("editCart")}</span></a>
            </header>
            {this.cartLines && <div className="cart-overview">
                {this.cartLines.map(x => "items" in x
                    ? <div className="cart-item">
                        <figure className="image">
                            <img width={80} height={100} loading="lazy" src={x.items[0].imageUrl} alt={x.title} />
                        </figure>
                        <div className="body">
                            <span className="title">{x.title}</span>
                            <div className="amount">
                                <div className="items">
                                    {x.items.map(y => <span>{`${y.quantity}x ${y.title}`}</span>)}
                                </div>
                                <div className="pricing align-right">
                                    {x.items[0].actionPrice > 0 && <span className="action">{m.trust(formatHtmlPrice(x.items[0].actionPrice))}</span>}
                                    <span className="price">{m.trust(formatHtmlPrice(x.items[0].totalPrice))}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="cart-item">
                        <figure className="image">
                            <img width="80" height="100" loading="lazy" src={x.imageUrl} alt={x.title} />
                        </figure>
                        <div className="body">
                            <span className="title">{x.title}</span>
                            <div className="amount">
                                <span className="quantity">{x.quantity}</span>
                                <div className="pricing align-right">
                                    {x.actionPrice > 0 && <span className="action">{m.trust(formatHtmlPrice(x.actionPrice))}</span>}
                                    <span className="price">{m.trust(formatHtmlPrice(x.totalPrice))}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>}
            <PriceOverview hideTitle={true} {...this.priceOverview} />
        </div>
    }
}