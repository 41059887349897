import m from "mithril";
import { MithrilTsxComponent } from "mithril-tsx-component";
import { Form } from "oj-form-utils";
import { FormRows } from "../../components/forms/formRow";
import { Svg } from "../../svg";
import { getTranslation } from "./translations";
import { postForm } from "oj-ajax";
import { cleanString } from "oj-utils";

interface ILoginRegisterForm {
    registerUrl: string
    redirectUrl?: string
}


export class LoginRegisterForm extends MithrilTsxComponent<ILoginRegisterForm> {
    isLoading = false

    form = new Form({
        emailAddressRegister: Form.field<any>("", getTranslation("email"), { required: getTranslation("fieldRequired") }, "text", {
            placeholder: getTranslation("enterEmail"),
        })
    })
    oninit(v: m.Vnode<ILoginRegisterForm>) {

    }

    view({ attrs: { registerUrl, redirectUrl } }: { attrs: ILoginRegisterForm }) {
        return (

            <div className="RegisterForm">
                <span className="h2">{getTranslation("newHere")}</span>
                <ul className="check-list">
                    <li>
                        <i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/checkmark.svg" /></i>
                        <span>{getTranslation("findPhotoProjectsBack")}</span>
                    </li>
                    <li>
                        <i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/checkmark.svg" /></i>
                        <span>{getTranslation("savedCredentialsFasterPayment")}</span>
                    </li>
                    <li>
                        <i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/checkmark.svg" /></i>
                        <span>{getTranslation("findBackOrders")}</span>
                    </li>
                </ul>
                <form onsubmit={async (e) => {
                    e.preventDefault();

                    if (!this.form.validate())
                        return;

                    this.isLoading = true

                    await postForm<string>(registerUrl, e.target)
                        .then(res => JSON.parse(res))
                        .then(res => {
                            if (res.exists) {
                                this.form.getFields().emailAddressRegister.errors.push(res.errorMessage)
                                m.redraw()
                            } else
                                location.href = !redirectUrl ? res.redirect : `${res.redirect}&registerRedirectUrl=${redirectUrl}`
                        })
                        .finally(() => this.isLoading = false)


                }} method="post">
                    <FormRows rows={[['emailAddressRegister']]} form={this.form} />
                    <div className="btn-wrapper">
                        <button className={cleanString("btn-default", this.isLoading && "loading")} disabled={this.isLoading}><span>{getTranslation("register")}</span></button>
                    </div>
                </form>
            </div>
        );
    }
}