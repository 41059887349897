const NL = {
    pageTitle: "bestelling afronden",
    pageDescription: "Controleer je gegevens en kies een betaalwijze",
    yourDetails: "je gegevens",
    deliveryAddress: "bezorgadres",
    editDetails: "wijzig gegevens",
    editDeliveryAddress: "wijzig bezorgadres",
    paymentSelectionTitle: "hoe wil je betalen?",
    free: "gratis",
    newsletterDescription: "Ja, ik ontvang graag de wekelijkse HEMA nieuwsbrieven en blijf graag op de hoogte van de laatste nieuwtjes, aanbiedingen, acties en producten van HEMA.",
    payNow: "betaal nu",
    agreeToTermsAndConditions: "met het bevestigen van je bestelling ga je akkoord met de algemene voorwaarden",
    yourOrder: "je bestelling",
    editCart: "wijzig je mandje",
    useInvoiceAddress: "gebruik factuur adres",
    sameAddress: "Het bezorgadres is gelijk aan het factuuradres.",
    suggestion: "Tip",
    isGift: "is het een cadeautje? Kies een ander bezorgadres.",
    sendCardAction: "Verstuur je een kaartje? Dan wordt het kaartje bezorgd op het adres dat je op het kaartje hebt ingevuld.",
    deliveryPickup: "ophalen in de winkel",
    editStore: "wijzig winkel",
    orderNow: "bestel nu",
};

const FR: typeof NL = {
    pageTitle: "finaliser la commande",
    pageDescription: "Vérifiez vos informations et choisissez un mode de paiement",
    yourDetails: "vos détails",
    deliveryAddress: "adresse de livraison",
    editDetails: "modifier les informations",
    editDeliveryAddress: "modifier l'adresse de livraison",
    paymentSelectionTitle: "Comment voulez-vous payer ?",
    free: "gratuit",
    newsletterDescription: "Oui, je souhaite recevoir les newsletters hebdomadaires de HEMA et rester informé(e) des dernières actualités, offres, promotions et produits de HEMA.",
    payNow: "Payer maintenant",
    agreeToTermsAndConditions: "en confirmant votre commande, vous acceptez les conditions générales",
    yourOrder: "votre commande",
    editCart: "modifier votre panier",
    useInvoiceAddress: "utiliser l'adresse de facturation",
    sameAddress: "L'adresse de livraison est la même que l'adresse de facturation.",
    suggestion: "Conseil",
    isGift: "est-ce un cadeau ? Choisissez une autre adresse de livraison.",
    sendCardAction: "Envoyez-vous une carte ? La carte sera livrée à l'adresse que vous avez indiquée sur la carte.",
    deliveryPickup: "retirer en magasin",
    editStore: "modifier le magasin",
    orderNow: "passer à la caisse",
};

const translations: Record<Window['culture'], typeof NL> = {
    NL,
    FR
};

export const getTranslation = <T extends LanguageKey>(key: T): typeof NL[T] => {
    if (window.culture === "fr-BE")
        return translations["FR"][key]

    return translations["NL"][key]
}

export type LanguageKey = keyof typeof NL