import m from "mithril";
import { MithrilTsxComponent } from "mithril-tsx-component";
import { Form } from "oj-form-utils";
import { FormRows } from "../../components/forms/formRow";
import { ForgotPasswordFlyout } from "./forgotPasswordFlyout";
import { postForm } from "oj-ajax";
import { getTranslation } from "./translations";
import { cleanString } from "oj-utils";

interface ILoginForm {
    token: string
    isFlyout: boolean
    loginUrl: string
    skipRedirect?: boolean
}

export class LoginForm extends MithrilTsxComponent<ILoginForm> {
    form
    isLoading = false

    oninit({ attrs: { token } }: { attrs: ILoginForm }) {
        this.form = new Form({
            emailaddress: Form.field<any>("", getTranslation("email"), { required: getTranslation("fieldRequired") }, "text", {
                placeholder: getTranslation("enterEmail"),
            }),
            password: Form.field<any>("", getTranslation("password"), { required: getTranslation("fieldRequired") }, "password", {
                placeholder: getTranslation("enterPassword"),
                className: "form-password"
            }),
            "__RequestVerificationToken": Form.field<any>(token, null, {}, "hidden", {})
        })
    }

    view({ attrs: { isFlyout, loginUrl, skipRedirect } }: { attrs: ILoginForm }) {
        return (
            <div className="LoginForm">
                <span className="h2">{getTranslation("login")}</span>
                <form onsubmit={async (e) => {
                    e.preventDefault();

                    if (!this.form.validate())
                        return;

                    this.isLoading = true

                    await postForm<string>(loginUrl, e.target)
                        .then(res => JSON.parse(res))
                        .then(res => {
                            if (res.redirect && !skipRedirect)
                                location.href = res.redirect
                            else if (!res.errorText && skipRedirect) {
                                location.reload()
                            } else {
                                this.form.getFields().emailaddress.errors.push("")
                                this.form.getFields().password.errors.push(res.errorText)
                                m.redraw()
                            }
                        })
                        .finally(() => this.isLoading = false)
                }} method="post">
                    <FormRows rows={[['emailaddress', 'password', '__RequestVerificationToken']]} form={this.form} />
                    <ForgotPasswordFlyout isFlyout={isFlyout} />
                    <div className="btn-wrapper">
                        <button className={cleanString("btn-secondary", this.isLoading && "loading")} disabled={this.isLoading}><span>{getTranslation("login")}</span></button>
                    </div>
                </form>
            </div>
        );
    }
}