import m from "mithril";
import { MithrilTsxComponent } from "mithril-tsx-component";
import { Flyout } from "oj-mithriljs-packages/dist/Flyout/Flyout";
import { Svg } from "../../svg";
import { Form } from "oj-form-utils";
import { FormRows } from "../../components/forms/formRow";
import { postForm } from "oj-ajax";
import { getTranslation } from "./translations";
import { hideScrollbars } from "../../utils";

interface IForgotPasswordFlyout {
    isFlyout: boolean
}

export class ForgotPasswordFlyout extends MithrilTsxComponent<IForgotPasswordFlyout> {
    open: boolean = false;
    mailSentTo: string;

    form = new Form({
        emailAddressForgotPassword: Form.field<any>("", getTranslation("email"), { required: getTranslation("fieldRequired") }, "text", {
            placeholder: getTranslation("enterEmail"),
        })
    })

    view({ attrs: { isFlyout } }: { attrs: IForgotPasswordFlyout }) {
        return (
            <div className="forgot-password">
                <a className="link" onclick={() => {
                    this.open = !this.open
                    if (!isFlyout)
                        hideScrollbars(true)
                }}>
                    <span>{getTranslation("forgotPassword")}</span>
                </a>
                <Flyout
                    className={`ForgotPasswordFlyout`}
                    open={this.open}
                    ondismiss={() => hideScrollbars(false)}
                    onopen={open => this.open = open}
                    dismiss
                    animate
                >
                    <header className="flyout-nav-header">
                        <button className="close" onclick={e => {
                            this.open = false
                            if (!isFlyout)
                                hideScrollbars(false)
                        }}><Svg src="/FrontendWebpack/dist/assets/newIcons/close.svg" />
                        </button>
                    </header>
                    <div className="flyout-nav-body">
                        {!this.mailSentTo ?
                            <div className="forgot-password-form">
                                <span className="h2">{getTranslation("forgotPassword")}</span>
                                <p>{getTranslation("forgotPasswordText1")}</p>
                                <p>{getTranslation("forgotPasswordText2")}</p>
                                <form onsubmit={async (e) => {
                                    e.preventDefault();

                                    if (!this.form.validate())
                                        return;

                                    const data = this.form.toJSON();

                                    await postForm<string>(`/${window.culture}/CustomerService/ForgotPassword/`, e.target)
                                        .then(() => {
                                            this.mailSentTo = data.emailAddressForgotPassword;
                                            m.redraw();
                                        })
                                }} method="post">
                                    <FormRows rows={[['emailAddressForgotPassword']]} form={this.form} />
                                    <div className="btn-wrapper">
                                        <button className="btn-secondary">{getTranslation("requestPassword")}</button>
                                    </div>
                                    <button className="link" onclick={e => {
                                        this.open = false
                                        if (!isFlyout)
                                            hideScrollbars(false)
                                    }}><Svg src="/FrontendWebpack/dist/assets/newIcons/arrow-left.svg" />
                                        <span>{getTranslation("back")}</span>
                                    </button>
                                </form>
                            </div> :
                            <div>
                                <h3>{getTranslation("mailSent1")}</h3>
                                <p>{getTranslation("mailSent2")} {this.mailSentTo}.</p>
                                <button className="close-btn btn-secondary" onclick={() => this.open = false}>
                                    {getTranslation("close")}
                                </button>
                            </div>}
                    </div>
                </Flyout>
            </div>
        );
    }
}