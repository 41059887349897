import m from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { childOf } from "oj-dom-utils"
import { Zmdi } from "oj-mithriljs-packages/dist/Zmdi/Zmdi"
import type { Dropzone } from "oj-mithriljs-packages/dist/FileUpload/FileUpload"
import { cleanString, fileToB64, increment } from "oj-utils"
import { getTranslation } from "../../translation"
import { IInputField } from "./inputFieldGroup"
import { Svg } from "../../svg"

interface ISelectOption { value: string, label: string }

interface ISelect {
    value: string
    placeholder?: string
    options: (string | ISelectOption)[]
    onchange: (value: string) => void
}

class Select extends MithrilTsxComponent<ISelect> {
    static increment = increment()
    id = Select.increment();
    open = false;

    view(v: m.Vnode<ISelect>) {
        let value = v.attrs.value || v.attrs.placeholder || v.attrs.options[0]
        const _value = v.attrs.options.find(x => value === (typeof x === "string" ? x : x.value))
        value = (_value && typeof _value !== "string") ? _value.label : value

        return <div className="select">
            <div className={cleanString("select-styled", this.open && "active", value === v.attrs.placeholder && "placeholder")} onclick={() => this.open = !this.open}>
                {value}
                <i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/chevron-down.svg" /></i>
            </div>
            {this.open && <ul className="select-options" style="display: block;">
                {v.attrs.options.map((x) => <li className={cleanString(value === (typeof x === "string" ? x : x.value) && "is-selected")} onclick={() => {
                    v.attrs.onchange(typeof x === "string" ? x : x.value)
                    this.open = false
                }}>{typeof x === "string" ? x : x.label}</li>)}
            </ul>}
        </div>
    }

    oncreate(v: m.VnodeDOM<ISelect>) {
        window.on(`click.select${this.id}`, (e) => {
            if (!this.open)
                return

            if (childOf(v.dom as HTMLElement, e.target))
                return

            this.open = false
            m.redraw()
        })
    }

    onremove() {
        window.off(`click.select${this.id}`)
    }
}

export class InputField extends MithrilTsxComponent<IInputField> {
    view(v: m.Vnode<IInputField>) {
        let { type, errors, value, setVal, data, key, validate, readonly, id, onchange } = v.attrs
        readonly = readonly || validate?.readonly
        const shouldValidate = Array.isArray(errors) ? errors.length > 0 : false;
        let placeholder = null;

        if (data && Object.hasOwn(data, "placeholder")) {
            placeholder = data.placeholder.toLowerCase();
        }


        if (type === "select")
            return <Select options={data.options} placeholder={placeholder} value={value} onchange={(value) => setVal(value, true)} />

        if (type === "checkbox")
            return <label className="checkbox">
                <input
                    readonly={readonly}
                    disabled={readonly}
                    name={key}
                    type="checkbox"
                    className="checkbox"
                    checked={value}
                    oninput={(e: any) => { setVal(e.target.checked, shouldValidate); onchange?.(e) }}
                    onchange={(e: any) => { setVal(e.target.checked, true); }}
                />
                <span className="form-control">
                    {data?.text && m.trust(data.text)}
                    <i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/checkmark.svg" /></i>
                </span>
            </label>



        if (type === "checkbox-list") {
            let checkboxValues = Array.isArray(value) ? value : []

            return data.options.map((x: any) => {
                return <label className="checkbox">
                    <input
                        readonly={readonly}
                        disabled={readonly}
                        name={x}
                        type="checkbox"
                        className="checkbox"
                        checked={checkboxValues.includes(x)}
                        oninput={() => {
                            if (checkboxValues.includes(x)) {
                                checkboxValues = checkboxValues.filter(y => y !== x)
                            }
                            else {
                                checkboxValues.push(x)
                            }

                            setVal(checkboxValues, shouldValidate)
                            onchange?.(checkboxValues)
                        }}
                        onchange={() => {
                            setVal(checkboxValues, true)
                        }}
                    />
                    <span className="form-control">
                        {m.trust(x)}
                        <i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/checkmark.svg" /></i>
                    </span>
                </label>
            })
        }

        if (type === "radio") {
            return data.options.map((x: any, i: number) =>
                <label className="radio" for={key + i}>
                    <input
                        id={key + i}
                        readonly={readonly}
                        disabled={readonly}
                        type="radio"
                        className="radio"
                        name={key}
                        checked={value === i}
                        oninput={() => { setVal(data.options.indexOf(x), shouldValidate); onchange?.(data.options.indexOf(x)) }}
                        onchange={() => setVal(data.options.indexOf(x), true)}
                    />
                    <span>{x}</span>
                </label>
            )
        }

        if (type === "textarea")
            return <textarea
                readonly={readonly}
                disabled={readonly}
                name={key}
                className="form-control textarea"
                placeholder={placeholder}
                value={value}
                oninput={(e: any) => { setVal(e.target.value, shouldValidate); onchange?.(e) }}
                onchange={(e: any) => setVal(e.target.value, true)}
            />

        if (type === "tag")
            return <List
                readonly={readonly}
                items={Array.isArray(value) ? value : []}
                onchange={items => setVal(items, true)}
            />

        if (type === "image")
            return <ImageUpload
                readonly={readonly}
                value={value}
                accept={data?.accept}
                onchange={image => setVal(image, true)}
            />

        if (type === "file") 
            return <FileUpload
                accept={data?.accept}
                readonly={readonly}
                value={value}
                onchange={image => setVal(image, true)}
            />

        return <input
            id={key ?? id}
            readonly={readonly}
            disabled={readonly}
            name={key}
            className={cleanString("form-control", data?.className)}
            type={type}
            value={value}
            placeholder={placeholder}
            autocomplete="off"
            oninput={(e: any) => { setVal(e.target.value, shouldValidate); onchange?.(e) }}
            onchange={(e: any) => { setVal(e.target.value, true); }}
        />
    }
}

export interface IImageUpload { value: string, readonly?: boolean, accept?: string[], onchange: (image: string) => void }
export class ImageUpload extends MithrilTsxComponent<IImageUpload> {
    static dropzone: typeof Dropzone;

    oninit(v: m.Vnode<IImageUpload>) {
        this.loadDropzone(v)
    }

    view(v: m.Vnode<IImageUpload>) {
        if (!ImageUpload.dropzone)
            return null

        return <div className="image-dropzone">
            <ImageUpload.dropzone prompt accept={v.attrs.accept?.join(",") ?? "image/jpeg,image/png"} onchange={e => {
                if (v.attrs.readonly)
                    return

                if (!e || !e[0])
                    return

                const file = e[0]
                e.length = 0
                this.convertFile(v, file)
            }}>
                {v.attrs.value && v.attrs.value.startsWith("data:image/") && <img src={v.attrs.value} />}
            </ImageUpload.dropzone>
        </div>
    }

    onbeforeupdate(v: m.Vnode<IImageUpload>) {
        this.loadDropzone(v)
    }

    async loadDropzone(v: m.Vnode<IImageUpload>) {
        if (ImageUpload.dropzone)
            return

        const dropzone = await import("oj-mithriljs-packages/dist/FileUpload/FileUpload").then(x => {
            ImageUpload.dropzone = x.Dropzone;
        })

        m.redraw()
    }

    async convertFile(v: m.Vnode<IImageUpload>, file: File) {
        const src = await fileToB64(file)
        v.attrs.onchange(src)
        m.redraw()
    }
}
export interface IFileUpload {
    readonly?: boolean
    onchange: (file: File) => void
}
export class FileUpload extends MithrilTsxComponent<IFileUpload> {
    file!: File
    static dropzone: typeof Dropzone;

    oninit(v: m.Vnode<IImageUpload>) {
        this.loadDropzone(v)
    }

    view(v: m.Vnode<IFileUpload>) {
        return <div className={cleanString("file-dropzone", this.file && "active")}>
            <FileUpload.dropzone prompt onchange={e => {
                if (v.attrs.readonly)
                    return

                if (!e || !e[0])
                    return

                this.file = e[0]
                e.length = 0
                v.attrs.onchange(this.file)
            }}>

            </FileUpload.dropzone>
            {this.file && [
                <span className="uploaded-file">
                    <span>{getTranslation('fileName')}:</span>&nbsp;
                    {
                        this.file.name.length > 16
                            ? `${this.file.name.substr(0, 6)}...${this.file.name.substr(-7)}`
                            : this.file.name
                    }
                </span>,
                <Zmdi icon="file" />
            ]}
        </div>
    }

    onbeforeupdate(v: m.Vnode<IImageUpload>) {
        this.loadDropzone(v)
    }

    async loadDropzone(v: m.Vnode<IImageUpload>) {
        if (FileUpload.dropzone)
            return

        const dropzone = await import("oj-mithriljs-packages/dist/FileUpload/FileUpload").then(x => {
            FileUpload.dropzone = x.Dropzone;
        })

        m.redraw()
    }
}


export interface IList { items: string[], readonly?: boolean, onchange: (list: string[]) => void }
export class List extends MithrilTsxComponent<IList> {
    inputRef!: HTMLInputElement

    view(v: m.Vnode<IList>) {
        return <div className="tags">
            <ul className="list">
                {v.attrs.items.map((x, i) =>
                    <li>
                        <button onclick={() => v.attrs.onchange([...v.attrs.items.slice(0, i), ...v.attrs.items.slice(i + 1)])}>x</button>
                        <span>{x}</span>
                    </li>
                )}
            </ul>
            <div className="add">
                <input readonly={v.attrs.readonly} type="text" oncreate={({ dom }) => this.inputRef = dom as HTMLInputElement} />
                {!v.attrs.readonly &&
                    <button className="btn btn-primary" onclick={() => {
                        const val = this.inputRef.value.trim()
                        if (!val)
                            return
                        if (v.attrs.items.includes(val))
                            return

                        v.attrs.onchange([...v.attrs.items, val])
                        this.inputRef.value = ""
                    }}>
                        <span>{getTranslation('add')}</span>
                    </button>
                }
            </div>
        </div>
    }
}