import m from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { cleanString } from "oj-utils"
import { Svg } from "../../svg"
import { mounter } from "../../utils"

interface IGlobalMessage {
    type: "error" | "info" | "alert" | "action"
    message: string,
    openInNewTab?: boolean,
    url?: string
}

const typeIcons = {
    "error": "msg-error",
    "info": "msg-info",
    "alert": "msg-alert",
    "action": "msg-discount"
}

export class GlobalMessage extends MithrilTsxComponent<IGlobalMessage> {
    view(v: m.Vnode<IGlobalMessage>) {
        return <div className="global-messages">
            {v.attrs.url
                ? <a href={v.attrs.url} target={v.attrs.openInNewTab ? "_blank" : "_self"} rel={v.attrs.openInNewTab ? "noreferrer noopener" : ""} className={cleanString("message-bar", v.attrs.type)}>
                    <Message {...v.attrs} />
                </a>
                : <div className={cleanString("message-bar", v.attrs.type)}>
                    <Message {...v.attrs} />
                </div>}
        </div>
    }
}

class Message extends MithrilTsxComponent<IGlobalMessage> {
    view(v: m.Vnode<IGlobalMessage>) {
        return <div className="message">
            <i className="icon">
                <Svg src={`/FrontendWebpack/dist/assets/newIcons/${typeIcons[v.attrs.type]}.svg`} />
            </i>
            <span className="title">{v.attrs.message}</span>
        </div>
    }
}

mounter<IGlobalMessage>(`[data-global-message]`, (r, d) => {
    m.mount(r, {
        oncreate: dom => r.replaceWith(dom.dom),
        view: v => <GlobalMessage root={r} {...d} />
    })
})