import m, { Vnode } from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { cleanString } from "oj-utils";

export interface ICheckoutSteps {
    steps: IStep[]
    currentStep: number
}

export interface IStep {
    step: number,
    url: string,
    title: string,
}

export class CheckoutSteps extends MithrilTsxComponent<ICheckoutSteps> {
    view(v: Vnode<ICheckoutSteps>) {
        const { steps, currentStep } = v.attrs;
        return steps?.length > 1 && <nav className="checkout-steps">
            {steps.map((x, i) => {
                if (x.step < currentStep)
                    return <a href={x.url} class="step"><span className="count">{i + 1}</span><span className="title">{x.title}</span></a>
                else
                    return <span class={cleanString("step", currentStep === x.step && "current")}><span className="count">{i + 1}</span><span className="title">{x.title}</span></span>
            })}
        </nav>
    }
}