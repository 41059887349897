import m from "mithril";
import { MithrilTsxComponent } from "mithril-tsx-component";
import { mounter } from "oj-dom-utils";
import { LoginForm } from "./loginForm";
import { LoginRegisterForm } from "./loginRegisterForm";

interface ILoginPage {
    token: string;
    loginUrl: string
    registerUrl: string
    redirectUrl: string
}

export class LoginPage extends MithrilTsxComponent<ILoginPage> {
    view({ attrs: { token, loginUrl, registerUrl, redirectUrl } }: { attrs: ILoginPage }) {
        return (
            <div className="LoginPage">
                <LoginForm token={token} isFlyout={false} loginUrl={loginUrl} />
                <LoginRegisterForm registerUrl={registerUrl} redirectUrl={redirectUrl} />
            </div>
        );
    }
}

mounter<ILoginPage>("[data-m-login-page]", (r, d) => {
    m.mount(r, {
        oncreate: dom => r.replaceWith(dom.dom),
        view: v => <LoginPage {...d} />
    })
})