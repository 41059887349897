import m, { parseQueryString, Vnode } from "mithril";
import { MithrilTsxComponent } from "mithril-tsx-component";
import { Form } from "oj-form-utils";
import { getTranslation } from "./translations";
import { FormRows } from "../../components/forms/formRow";
import { getAddress } from "../../utils";
import { INewsletterSubscribe, NewsletterSubscribe } from "../orderCheck/newsletterSubscribe";
import { Svg } from "../../svg";
import { cleanString } from "oj-utils";

interface IRegisterForm extends INewsletterSubscribe {
    redirectUrl: string
    onError: (errorMessage) => void
}

const nlPostalCodeRegexp = /^[1-9][0-9]{3}\s?[A-Z]{2}$/i;
const bePostalCodeRegexp = /^[1-9][0-9]{3}$/;

export class RegisterForm extends MithrilTsxComponent<IRegisterForm> {
    isLoading = false
    redirectUrl = ""

    form = new Form({
        emailAddress: Form.field<any>(parseQueryString(location.search).email, getTranslation("email"), {
            readonly: true,
            required: getTranslation("fieldRequired"),
            patterns: [{ pattern: Form.patterns.email, message: getTranslation("invalidEmail") }],
        }, "text", {
            placeholder: getTranslation("enterEmail"),
        }),
        gender: Form.field<any>(2, getTranslation("howToAddress"), { required: getTranslation("fieldRequired") }, "radio", {
            options: [getTranslation("mr"), getTranslation("mrs"), getTranslation("neutral")],
            className: "form-control"
        }),
        firstName: Form.field<any>("", getTranslation("firstName"), { required: getTranslation("fieldRequired") }, "text", {
            placeholder: getTranslation("firstName"),
        }),
        middleName: Form.field<any>("", getTranslation("middleName"), {}, "text", {
            placeholder: getTranslation("middleName"),
        }),
        lastName: Form.field<any>("", getTranslation("lastName"), { required: getTranslation("fieldRequired") }, "text", {
            placeholder: getTranslation("lastName"),
        }),
        companyName: Form.field<any>("", getTranslation("companyName"), {}, "text", {
            placeholder: getTranslation("companyName"),
        }),
        country: Form.field<any>("", getTranslation("country"), { required: getTranslation("fieldRequired"), readonly: true }, "text", {
            placeholder: getTranslation("country"),
        }),
        postalCode: Form.field<any>("", getTranslation("postalCode"), {
            required: getTranslation("fieldRequired"),
            patterns: [{ pattern: window["culture"] === "nl-NL" ? nlPostalCodeRegexp : bePostalCodeRegexp, message: getTranslation("invalidPostalCode") }]
        }, "text", {
            placeholder: getTranslation("postalCode"),
        }),
        houseNumber: Form.field<any>("", getTranslation("houseNumber"), { required: getTranslation("fieldRequired") }, "text", {
            placeholder: getTranslation("houseNumber"),
        }),
        addition: Form.field<any>("", getTranslation("addition"), {}, "text", {
            placeholder: getTranslation("addition"),
        }),
        street: Form.field<any>("", getTranslation("address"), { required: getTranslation("fieldRequired"), readonly: true }, "text", {
            placeholder: getTranslation("address"),
        }),
        city: Form.field<any>("", getTranslation("city"), { required: getTranslation("fieldRequired"), readonly: true }, "text", {
            placeholder: getTranslation("city"),
        }),
        phoneNumber: Form.field<any>("", getTranslation("phoneNumber"), {
            patterns: [{ pattern: Form.patterns.phone, message: getTranslation("invalidPhone") }]
        }, "text", {
            placeholder: getTranslation("phoneNumber"),
        }),
        password: Form.field<any>("", getTranslation("password"), {
            required: getTranslation("fieldRequired"),
            min: { value: 8, message: getTranslation("passwordMinLength") },
            max: { value: 20, message: getTranslation("passwordMaxLength") },
        }, "password", {
            placeholder: getTranslation("enterPassword"),
            className: "form-password"
        }),
        confirmPassword: Form.field<any>("", getTranslation("confirmPassword"), {
            required: getTranslation("fieldRequired"),
            custom: [(v, form) => {
                if (v !== form.getFields().password.value)
                    return getTranslation("passwordsDoNotMatch")
                return false
            }]
        }, "password", {
            placeholder: getTranslation("enterPassword"),
            className: "form-password"
        }),
    })

    oninit(v: m.Vnode<IRegisterForm>) {
        this.redirectUrl = v.attrs.redirectUrl ?? parseQueryString(location.search).registerRedirectUrl?.toString() ?? ""
        if (window.culture === "fr-BE" || window.culture === "nl-BE")
            this.form.setVal("country", getTranslation("belgium"));
        else if (window.culture === "nl-NL")
            this.form.setVal("country", getTranslation("netherlands"));
    }

    view(v: m.Vnode<IRegisterForm>) {
        return (
            <div className="register-form">
                <span className="h2">{getTranslation("registerTitle")}</span>
                <p>{getTranslation("enterDetails")}</p>
                <div className="form">
                    <FormRows rows={[['emailAddress', 'gender']]} form={this.form} />
                    <div className="three-columns mobile">
                        <FormRows rows={[['firstName', 'middleName', 'lastName']]} form={this.form} />
                    </div>
                    <FormRows rows={[['companyName']]} form={this.form} />
                    <div className="country-wrapper">
                        <FormRows rows={[['country']]} form={this.form} />
                        {window["culture"] === "nl-NL"
                            ? <i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/flag-nl.svg" /></i>
                            : <i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/flag-be.svg" /></i>}

                    </div>
                    <div className="three-columns">
                        <FormRows rows={[['postalCode', 'houseNumber', 'addition']]} form={this.form} onchange={() => getAddress(this.form)} />
                    </div>
                    <FormRows rows={[['street', 'city', 'phoneNumber', 'password']]} form={this.form} />
                    <div className="password-hint">
                        <span>
                            {getTranslation("passwordHint")}
                        </span>
                    </div>
                    <FormRows rows={[['confirmPassword']]} form={this.form} />
                    <NewsletterSubscribe {...v.attrs} />
                    <div className="btn-wrapper">
                        <button type="button" className={cleanString("btn-secondary", this.isLoading && "loading")} disabled={this.isLoading} onclick={() => this.submit(v)}>
                            <span>{getTranslation("register")}</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    oncreate(v: m.VnodeDOM<IRegisterForm>) {
        v.dom.on("keyup", (e) => {
            if (e.code == "Enter" && e.target.nodeName != "TEXTAREA")
                this.submit(v)
        })
    }

    async submit(v: m.Vnode<IRegisterForm>) {
        if (!this.form.validate()) {
            m.redraw()
            return;
        }

        this.isLoading = true

        const json = this.form.toJSON()

        if (json.gender === 2)
            json.gender = 0
        else json.gender = json.gender + 1

        try {
            const res = await fetch(`/${window["culture"]}/CustomerService/Register?redirectUrl=${this.redirectUrl}`, {
                method: "POST",
                headers: { "Content-Type": "application/json", "Accept-Language": window["culture"] },
                body: JSON.stringify(json)
            })
            const data: any = await res.json()
            if (res.status === 400)
                throw new Error("error with register")

            if (data["redirectUrl"])
                window.location.href = data["redirectUrl"]

            if (data["ErrorMessage"])
                v.attrs.onError(data["ErrorMessage"])
        }
        catch (err) {
        }
        finally {
            this.isLoading = false
        }
        m.redraw()
    }
}
