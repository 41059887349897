const NL = {
    shoppingCart: "winkelmandje",
    delivery: "bezorging",
    edit: "aanpassen",
    free: "gratis",
    pickUpInStore: "afhalen in de winkel",
    homeDelivery: "thuisbezorgen (binnen Nederland)",
    receipt: "bonnetje",
    totalPriceItems: "totale prijs artikelen",
    processingFees: "verwerkingskosten",
    deliveryFees: "bezorgkosten",
    totalAmount: "totaalbedrag",
    continue: "ga verder",
    discount: "korting",
    discountCode: "kortingscode",
    discountCodeNotFound: "dit is geen geldige kortingscode",
    cartEmpty: "je winkelmandje is nog leeg...",
    addDiscount: "korting toevoegen",
    maxDiscountCodes: "maximaal één kortingscode per bestelling",
    customDiscountCode: "aangepaste kortingscode",
    add: "voeg toe",
    addCodeHere: "voer hier je code in",
    proceed: "doorgaan",
    somethingWentWrong: "er is iets fout gegaan",
    useVouchersTitle: "Gebruik maken van je persoonlijke vouchers ?",
    useVouchersDescription: "Log in en selecteer welke jij wilt gebruiken.",
    noVouchers: "Er zijn op dit moment geen actieve vouchers beschikbaar.",
    login: "inloggen",
    savedPoints: "gespaarde punten",
    points: "punten",
    withinTheNetherlands: "binnen Nederland",
    withinBelgium: "binnen belgië",
    expires: "verloopt",
    loadingVouchers: "Vouchers worden opgehaald...",
};

const FR: typeof NL = {
    shoppingCart: "panier",
    delivery: "livraison",
    edit: "ajuster",
    free: "gratuit",
    pickUpInStore: "retirer en magasin",
    homeDelivery: "livraison à domicile (aux Pays-Bas)",
    receipt: "ticket de caisse",
    totalPriceItems: "prix total des articles",
    processingFees: "frais de traitement",
    deliveryFees: "frais de livraison",
    totalAmount: "montant total",
    continue: "continue",
    discount: "remise",
    discountCode: "code de réduction",
    discountCodeNotFound: "ce n'est pas un code de réduction valide",
    cartEmpty: "votre panier est encore vide...",
    addDiscount: "ajouter une remise",
    maxDiscountCodes: "maximum une code de réduction par commande",
    customDiscountCode: "code de réduction personnalisé",
    add: "ajoute",
    addCodeHere: "entrez votre code ici",
    proceed: "continuer",
    somethingWentWrong: "il y a eu un problème",
    useVouchersTitle: "Utiliser vos bons personnels ?",
    useVouchersDescription: "Connectez-vous et sélectionnez celui que vous souhaitez utiliser.",
    noVouchers: "Il n'y a pas de bons actifs disponibles pour le moment.",
    login: "se connecter",
    savedPoints: "points sauvegardés",
    points: "points",
    withinTheNetherlands: "aux Pays-Bas",
    withinBelgium: "en Belgique",
    expires: "expire",
    loadingVouchers: "Chargement des bons...",
};

const translations: Record<Window['culture'], typeof NL> = {
    NL,
    FR
};

export const getTranslation = <T extends LanguageKey>(key: T): typeof NL[T] => {
    if (window.culture === "fr-BE")
        return translations["FR"][key]

    return translations["NL"][key]
}

export type LanguageKey = keyof typeof NL