import { type IDeliveryOption } from "./deliveryOption";
import { debounce } from "oj-promise-utils";
import { type IPriceOverview } from "./priceOverview";
import m from "mithril"
import { type IShoppingCart } from "../shoppingCart";

export interface ICartGroupItem {
  id: string,
  title: string,
  items: ILocalCartItem[]
}

export interface ICartItem {
  groupId?: string,
  groupTitle?: string,
  id: string,
  imageUrl?: string,
  title?: string,
  descriptionFirstLine?: string,
  descriptionSecondLine?: string,
  actionPrice: number,
  totalPrice: number,
  quantity: number,
  prevQuantity?: number,
  isQuantityMutable?: boolean,
  backUrl: string,
  disablePickUpInStore: boolean,
  disableDeliveryByMail: boolean,
  tagmanagerData: any
}

export interface ILocalCartItem extends ICartItem {
  description: string[];
}

interface IState {
  cartLines: (ICartGroupItem | ILocalCartItem)[]
  deliveryOptions: IDeliveryOption[]
  priceOverview: IPriceOverview
  url: string
}

const state: IState = {
  cartLines: [],
  deliveryOptions: [],
  priceOverview: null,
  url: null
}

export const setCartLines = (cartLines: ICartItem[]) => {
  state.cartLines = convertCartLines(cartLines)
}

export const convertCartLines = (cartLines: ICartItem[]): (ICartGroupItem | ILocalCartItem)[] => {
  const lines = []

  for (const x of cartLines) {
    const lx = { ...x } as ILocalCartItem;
    lx.description = [lx.descriptionFirstLine, lx.descriptionSecondLine]
    lx.imageUrl = lx.imageUrl ?? "/FrontendWebpack/dist/assets/images/notfound/fallback-image.png"

    if (lx.groupId) {
      const g = lines.find(y => "items" in y && y.id === lx.groupId) as ICartGroupItem
      if (g)
        g.items.push(lx)
      else
        lines.push({ id: lx.groupId, title: lx.groupTitle, items: [lx] })
    }
    else
      lines.push(lx)
  }

  return lines
}

export const setDeliveryOptions = (options: IDeliveryOption[]) => {
  options.find(x => x.isEnabled).selected = true
  state.deliveryOptions = options
}

export const setPriceOverview = (PriceOverview: IPriceOverview) =>
  state.priceOverview = PriceOverview

export const getCartLines = () =>
  state.cartLines

export const getCartLinesSize = () =>
  state.cartLines.length

export const getPriceOverview = () =>
  state.priceOverview

export const getUrl = () =>
  state.url

export const setUrl = (url: string) =>
  state.url = url

export const getDeliveryOptions = () =>
  state.deliveryOptions

export const findCartItem = (id: string, groupId?: string) => {
  const items = groupId == undefined
    ? state.cartLines as ICartItem[]
    : (state.cartLines.find(x => "items" in x && x.id === groupId) as ICartGroupItem)?.items
  const item = items.find(x => !("items" in x) && x.id === id)
  return item
}

export const updateItemQuantity = async (id: string, quantity: number, groupId?: string) => {
  const item = findCartItem(id, groupId)

  if (quantity > 0) {
    item.prevQuantity ??= item.quantity;
    item.quantity = quantity;
    await apiUpdateQuantity(item)
    window.ojtag('add_to_cart', { items: [item.tagmanagerData] })
  }
}

export const removeItem = async (id: string, groupId?: string) => {
  const item = findCartItem(id, groupId)

  await apiRemoveItem(id, groupId)
  window.ojtag('remove_from_cart', { items: [item.tagmanagerData] })
}

export const updateDeliveryMethod = async (index: number) => {
  await apiUpdateDeliveryMethod(index)
}

export const updateCart = (data: IShoppingCart) => {
  setCartLines(data.shoppingCartLines)
  setPriceOverview({
    subPrice: data.subPrice,
    processingPrice: data.processingPrice,
    shippingPrice: data.shippingPrice,
    discountPrice: data.discountPrice,
    totalPrice: data.totalPrice,
    loyaltyHemaPoints: data.loyaltyHemaPoints,
  })
  setDeliveryOptions(data.orderDeliveryOptions)
  setUrl(data.url)
  // emit event for basket amount
  window["ea_BasketAmount"].emit("basketAmountChanged", data.shoppingCartLines.reduce((acc, item) => acc + item.quantity, 0));
}

const apiUpdateQuantity = debounce(200, async (item: ICartItem) => {
  try {
    const res = await fetch(`/Checkout/UpdateShoppingCartLine`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "Accept-Language": window["culture"] },
      body: JSON.stringify({ id: item.id, quantity: item.quantity })
    })
    const data: IShoppingCart = await res.json()
    if (res.status === 400)
      throw new Error("error with cart quantity update")
    delete item.prevQuantity

    updateCart(data)
  }
  catch (err) {
    // item.quantity = item.prevQuantity
    delete item.prevQuantity
    console.error(err)
  }
  m.redraw()
})

export const apiRemoveItem = debounce(200, async (id: string, groupId?: string) => {
  try {
    const res = await fetch(`/Checkout/DeleteShoppingCartLine`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "Accept-Language": window["culture"] },
      body: JSON.stringify({ id: id, groupId: groupId })
    })
    const data: IShoppingCart = await res.json()
    if (res.status === 400)
      throw new Error("error with remove cart item")

    updateCart(data)
  }
  catch (err) {
    console.error(err)
  }
  m.redraw()
})

export const apiUpdateDeliveryMethod = debounce(200, async (index: number) => {
  try {
    const res = await fetch(`/Checkout/SetShoppingCartDeliveryMethod`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "Accept-Language": window["culture"] },
      body: JSON.stringify({ deliveryMethod: index })
    })
    const data: IShoppingCart = await res.json()
    if (res.status === 400)
      throw new Error("error with delivery method update")

    updateCart(data)
  }
  catch (err) {
    console.error(err)
  }
  m.redraw()
})
