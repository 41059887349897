import m from "mithril";
import { MithrilTsxComponent } from "mithril-tsx-component";
import { mounter } from "oj-dom-utils";
import { RegisterForm } from "./registerForm";
import * as Logic from "../shoppingCart/logic"
import { type IShoppingCart } from "../shoppingCart";
import { type ICheckoutSteps } from "../checkout/checkoutSteps";
import { CheckoutSteps } from "../checkout/checkoutSteps";
import { CartSummary } from "../checkout/cartSummary";
import { type INewsletterSubscribe } from "../orderCheck/newsletterSubscribe";
import { GlobalMessage } from "../../components/messages/globalMessage";

interface IRegisterPage extends IShoppingCart, ICheckoutSteps, INewsletterSubscribe {
    registerRedirectUrl: string
}

class RegisterPage extends MithrilTsxComponent<IRegisterPage> {
    errorMessage = null;

    oninit(v: m.Vnode<IRegisterPage>) {
        Logic.setPriceOverview({
            subPrice: v.attrs.subPrice,
            processingPrice: v.attrs.processingPrice,
            shippingPrice: v.attrs.shippingPrice,
            discountPrice: v.attrs.discountPrice,
            totalPrice: v.attrs.totalPrice,
            loyaltyHemaPoints: v.attrs.loyaltyHemaPoints
        })
    }

    oncreate(v: m.Vnode<IRegisterPage>) {
    }

    view(v: m.Vnode<IRegisterPage>) {
        return [
            this.errorMessage && <section className="sm">
                <GlobalMessage type="alert" message={this.errorMessage} />
            </section>,
            <section className="sm">
                <div className="container">
                    <div className="checkout">
                        <div className="main">
                            <CheckoutSteps {...v.attrs} />
                        </div>
                    </div>
                    <div className="checkout">
                        <div className="main">
                            <RegisterForm {...v.attrs} redirectUrl={v.attrs.registerRedirectUrl} onError={(errorMessage) => {
                                this.errorMessage = errorMessage
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth'
                                });
                            }} />
                        </div>
                        <div className="side checkout-summary">
                            <CartSummary {...v.attrs} />
                        </div>
                    </div>
                </div>
            </section>
        ]
    }
}

mounter<IRegisterPage>("[data-m-register-page-checkout]", (r, d) => {
    m.mount(r, {
        oncreate: dom => {
            const parent = dom.dom.parentElement
            const target = parent.parentElement
            target.append(...Array.from(parent.childNodes))
            parent.remove()
        },
        view: v => <RegisterPage {...d} />
    })
})