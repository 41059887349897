import m from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { IFormField } from "oj-form-utils"
import { InputField } from "./inputField"
import { cleanString } from "oj-utils"
import { getTranslation } from "../../translation"
import { Svg } from "../../svg"

export interface IInputField extends IFormField<any> {
  setVal: (val: any, validate?: boolean) => void
  readonly?: boolean
  onsubmit?: (e: any) => void
  onchange?: (e: any) => void
  id?: string
}

export enum Size {
  s = "-small",
  m = "-medium",
  l = "-large",
  xl = "-extra-large"
}

let scrollToFirstErrorTimer: any = null
export const scrollToFirstError = (dom: Element) => {
  if (scrollToFirstErrorTimer)
    return

  dom.scrollIntoView({ behavior: "smooth", block: "center" })
  scrollToFirstErrorTimer = setTimeout(() => scrollToFirstErrorTimer = null, 100)
}

export class FormGroup extends MithrilTsxComponent<IInputField> {
  isPasswordShown = false;

  view(v: m.Vnode<IInputField>) {
    const { label, errors, type, data, key } = v.attrs;

    if (v.attrs.validate?.ignored)
      return null

    return <div className={cleanString("form-group", data?.size != null ? data.size : "", (errors ?? []).length > 0 && "invalid -invalid")}
      onkeydown={(e: any) => {
        if (e.code === "Enter" && v.attrs.onsubmit && e.target.nodeName !== "TEXTAREA") {
          v.attrs.onsubmit(e)
          m.redraw()
        }
      }}
    >
      {label && <div className="form-label">
        <label for={key}>
          {m.trust(label.toString())} {!Object.hasOwn(v.attrs.validate, "required") && <span className="optional">{getTranslation('optional')}</span>}
        </label>
      </div>}

      <div className={cleanString("form-input", this.checkIfGroup(type ?? ''))}>
        <InputField {...v.attrs} type={type === "password" ? this.isPasswordShown ? "text" : "password" : type} onsubmit={v.attrs.onsubmit} onchange={v.attrs.onchange} />
        {m.fragment({ key: key },
          [type === "password" && <button type="button" className="icon toggle-password" onclick={e => {
            e.preventDefault();
            this.isPasswordShown = !this.isPasswordShown;
            if (!this.isPasswordShown) {
              m.redraw()
            }
          }}>
            {!this.isPasswordShown
              ? <Svg src="/FrontendWebpack/dist/assets/newIcons/see-password.svg" />
              : <Svg src="/FrontendWebpack/dist/assets/newIcons/not-see-password.svg" />}
          </button>]
        )}
      </div>

      {(errors ?? []).length > 0 &&
        <div className="form-error" oncreate={({ dom }) => scrollToFirstError(dom)}>
          {(errors ?? []).map(x =>
            <span>{x}</span>
          )}
        </div>
      }
    </div>
  }

  checkIfGroup(type: string) {
    if (type === "radio")
      return "group-horizontal"
    if (type === "checkbox-list")
      return "group-vertical"
    if (type === "password")
      return "form-password"

    return ""
  }
}