import m from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { Form } from "oj-form-utils"
import { FormGroup } from "./inputFieldGroup"

export interface IFormRow {
    fields: string[]
    form: Form<any>
    readonly?: boolean
    onsubmit?: (e: any) => void
    onchange?: (e: any) => void
}

export class FormRow extends MithrilTsxComponent<IFormRow> {
    view(v: m.Vnode<IFormRow>) {
        const fields = v.attrs.fields.filter(x => !v.attrs.form.getIgnored(x))

        if (fields.length < 1)
            return null

        return <div className="form-row">
            {fields.map(Form.fieldHelper<any>(v.attrs.form, field =>
                <FormGroup {...field} readonly={v.attrs.readonly} onsubmit={v.attrs.onsubmit} onchange={v.attrs.onchange} />
            ))}
        </div>
    }
}

export interface IFormRows {
    rows: string[][]
    form: Form<any>
    readonly?: boolean
    onsubmit?: (e: any) => void
    onchange?: (e: any) => void
}

export class FormRows extends MithrilTsxComponent<IFormRows> {
    view(v: m.Vnode<IFormRows>) {
        return v.attrs.rows.map(row =>
            <FormRow fields={row} {...v.attrs} onchange={v.attrs.onchange} />
        )
    }
}