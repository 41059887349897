import m, { Vnode } from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import { getTranslation } from "./translations"
import { Svg } from "../../svg"

export interface INewsletterSubscribe {
    newsletterSubscribed: boolean
}


export class NewsletterSubscribe extends MithrilTsxComponent<INewsletterSubscribe> {
    checked: boolean

    oninit(v: Vnode<INewsletterSubscribe>) {
        this.checked = v.attrs.newsletterSubscribed
    }

    view(v: Vnode<INewsletterSubscribe>) {
        return <div className="newsletter-subscribe">
            <div className="form-group">
                <div className="form-input">
                    <label className="checkbox">
                        <input type="checkbox" name="newsletterSubscribe" checked={this.checked} onchange={() => {
                            this.checked = !this.checked
                            this.submit(this.checked)
                        }} />
                        <span className="form-control">
                            {getTranslation("newsletterDescription")}
                            <i className="icon"><Svg src="/FrontendWebpack/dist/assets/newIcons/checkmark.svg" /></i>
                        </span>
                    </label>
                </div>
            </div>
        </div>
    }

    async submit(newsletterSubscribe: boolean) {
        try {
            const res = await fetch(`/Checkout/UpdateNewsletterSubscription`, {
                method: "POST",
                headers: { "Content-Type": "application/json", "Accept-Language": window["culture"] },
                body: JSON.stringify({ newsletterSubscribe })
            })
        }
        catch (err) {
            console.error(err)
        }
        m.redraw()
    }
}